<!--<template>-->
<!--  <div class="content pageInit">-->
<!--    <div class="contentContainer" v-for="item in contents" :key="item">-->
<!--      <h1 class="pageTitle textColor">{{ item.title }}</h1>-->
<!--      <div class="imgContainer">-->
<!--        &lt;!&ndash;<div&ndash;&gt;-->
<!--        &lt;!&ndash;    class="oneImg"&ndash;&gt;-->
<!--        &lt;!&ndash;    v-for="jtem in item.data"&ndash;&gt;-->
<!--        &lt;!&ndash;    :key="jtem"&ndash;&gt;-->
<!--        &lt;!&ndash;    @click="goSecond(jtem)"&ndash;&gt;-->
<!--        &lt;!&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;  <imgBox :data="jtem" :width="350" :showName="true"></imgBox>&ndash;&gt;-->
<!--        &lt;!&ndash;</div>&ndash;&gt;-->

<!--        <li-->
<!--            v-for="jtem in item.data"-->
<!--            class="infinite-list-item contentsLi"-->
<!--            :key="jtem"-->
<!--            @click="goSecond(jtem)"-->
<!--            style="cursor: pointer;width:28%;margin-right: 35px;"-->
<!--        >-->
<!--          <imgBoxFlex-->
<!--              :data="jtem"-->
<!--              :paddingBottom="42"-->
<!--              :titleOnTop="false"-->
<!--              :showShadow="true"-->
<!--              :have-shadow="client=='inpixon'?false:true"-->
<!--          ></imgBoxFlex>-->
<!--        </li>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<template>
  <div class="content pageInit">
    <h1 class="pageTitle textColor">Airheads Sessions</h1>
    <div class="contentContainer">
      <!--<h1 class="pageTitle textColor">{{ item.title }}</h1>-->
      <el-row class="imgContainer" gutter="30">
        <el-col
            :md="8"
            :xs="24"
            v-for="item in contents"
            :key="item"
            class="infinite-list-item contentsLi"
        >
          <imgBoxFlex
              :data="item"
              :paddingBottom="42"
              :titleOnTop="false"
              :showShadow="true"
              :have-shadow="client=='inpixon'?false:true"
              style="cursor: pointer;"
              @clickImg="goSecond(item)"
          ></imgBoxFlex>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
import imgBox from "@/components/page/imgBox.vue";
import imgBoxFlex from "@/components/page/imgBoxFlex.vue";
import {useRouter} from "vue-router";
import axios from '@/api/http'
import {ElLoading} from 'element-plus';


export default defineComponent({
  name: "AllContents",
  components: {
    imgBox,
    imgBoxFlex
  },
  setup() {
    const oScript = document.createElement('scirpt');
    oScript.type = 'text/javascript';
    oScript.src = 'https://cx-app-aruba.uc.r.appspot.com/files/event/jsonA.json';
    document.getElementsByTagName('head')[0].appendChild(oScript);
    oScript.onload = function () {
      console.log("==== 123123123=====",)
    }
    const client = localStorage.getItem("eventClient");
    const data = reactive({
      contents: [
        // {
        //   title: "CORPORATE TOPICS",
        //   data: [
        //     {
        //       id: "1",
        //       name: "FINANCIAL SERVICES",
        //       img: require("../../assets/imgs/carousel1.jpeg"),
        //     },
        //     {
        //       id: "2",
        //       name: "HEALTHCARE",
        //       img: require("../../assets/imgs/hulk.jpg"),
        //     },
        //     {
        //       id: "3",
        //       name: "PUBLIC SECTOR",
        //       img: require("../../assets/imgs/content.jpg"),
        //     },
        //     {
        //       id: "4",
        //       name: "RETAIL",
        //       img: require("../../assets/imgs/content.jpg"),
        //     },
        //     {
        //       id: "5",
        //       name: "MANUFACTURING",
        //       img: require("../../assets/imgs/content.jpg"),
        //     },
        //     {
        //       id: "6",
        //       name: "TELCO",
        //       img: require("../../assets/imgs/content.jpg"),
        //     },
        //   ],
        // },
        // {
        //   title: "VERTICALS",
        //   data: [
        //     {
        //       id: "7",
        //       name: "APPLICATION MODERNIZATION",
        //       img: require("../../assets/imgs/content.jpg"),
        //     },
        //   ],
        // },
        // {
        //   title: "SOLUTIONS",
        //   data: [
        //     {
        //       id: "8",
        //       name: "FINANCIAL SERVICES",
        //       img: require("../../assets/imgs/content.jpg"),
        //     },
        //   ],
        // },
      ],
    });
    const router = useRouter(); // 第一步

    const goSecond = (data) => {
      if (data.action_type == 'inner') {
        // router.replace(data.action_url)
        router.push(data.action_url)
      } else {
        if (data.action_url) {
          window.open(data.action_url)
        }
      }
    };
    const formatData = (data) => {
      console.log("====data~~ =====", data)
      let arr = [];
      data.map(item => {
        let arrSub = [];
        let a = {};
        item.map(jtem => {
          let obj = {};
          obj['img'] = jtem.img;
          obj['id'] = jtem.id;
          obj['name'] = jtem.name;
          obj['title'] = jtem.tagTitle;
          arrSub.push(obj);
        })
        a['title'] = item[0].tagTitle;
        a['data'] = arrSub;
        arr.push(a);
      })
      return arr
    };
    // const getData = () => {
    //   axios.request({
    //     method: 'PATCH',
    //     url: '/virtual/tracks',
    //     data: {
    //       meetingId: 'a7yyxczuty0qpjo2393gmyad'
    //       // meetingId: localStorage.eventsMeetingId,
    //
    //     }
    //   }).then(res => {
    //     if (res.data.result == 200) {
    //       data.contents = formatData(res.data.data);
    //     }
    //   }).catch(err => {
    //     console.log(err);
    //   })
    // }

    // const getData = () => {
    //   let d = [
    //     {
    //       id: "1hf8vlkytidlg6qu6xuzwukf",
    //       img: "https://cx-app-aruba.appspot.com/files/default.png",
    //       name: "Just for Fun",
    //       action_type:'inner'
    //     },
    //     {
    //       id: "1nshbnh60synlnw8vdszsgad",
    //       img: "https://cx-app-aruba.appspot.com/files/default.png",
    //       name: "Day 3 Stream 2 Sessions English",
    //       action_type:'inner'
    //     },
    //     {
    //       id: "3ahf4hthbgwdgom76bki38qn",
    //       img: "https://cx-app-aruba.appspot.com/files/default.png",
    //       name: "Keynotes Day 1",
    //       action_type:'inner'
    //     },
    //     {
    //       id: "3wbil3qf9zgvk2uumh5s1j4f",
    //       img: "https://cx-app-aruba.appspot.com/files/default.png",
    //       name: "Flexible Services and Financing",
    //       action_type:'inner'
    //     },
    //     {
    //       id: "3wbil3qf9zgvk2uumh5s1j4f",
    //       img: "https://cx-app-aruba.appspot.com/files/default.png",
    //       name: "Flexible Services and Financing",
    //       action_type:'inner'
    //     },
    //     {
    //       id: "",
    //       img: "https://cx-app-aruba.appspot.com/files/default.png",
    //       name: "External link",
    //       action_type:'external',
    //       action_url:'https://www.google.com'
    //     },
    //   ]
    //   data.contents =d;
    // }

    const getData = () => {
      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'GET',
        url: '/files/event/jsonAirHeads.json',
      }).then(res => {
        console.log('res:', res);
        data.contents = res.data.data;
        loading.close();
      }).catch(err => {
        console.log(err);
        loading.close();
      })
    }
    getData();
    onBeforeMount(() => {
    });
    onMounted(() => {
    });
    return {
      ...toRefs(data),
      goSecond,
      getData,
      client
    };
  },
});
</script>
<style scoped lang='scss'>
.content {
  padding-right: 20px;
  box-sizing: border-box;

  .contentContainer {
    &:not(:first-child) {
      margin-top: 20px;
    }

    .imgContainer {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      .oneImg {
        // width: 25%;
        max-width: 380px;
        margin: 0 30px 30px 0;
        position: relative;
        cursor: pointer;
        box-shadow: 0px 0px 5px #c5c7ca;

        img {
          width: 100%;
          height: 100%;
        }

        // p {
        //   position: absolute;
        //   color: #fff;
        //   font-size: 20px;
        //   font-weight: bold;
        //   top: 50%;
        //   width: 100%;
        //   text-align: center;
        // }
      }
    }
  }
}
</style>
